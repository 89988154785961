import React, { useMemo, useState } from 'react';
import { Navigate, useRoutes, useLocation, Outlet } from 'react-router-dom';
import { toast } from "react-toastify";
// layouts
import DashboardLayout from './layouts/dashboard';
// Pages
import Dashboard from './pages/Dashboard';

import About from './pages/About';
import Home from './pages/Home1';
import Services from './pages/services/Services';
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import ProjectLogin from './pages/ProjectLogin';
import LayoutImageAdmin from './pages/layout/admin/LayoutImageAdmin';
// import LayoutImageAdmin from './pages/layout_images/LayoutImage3';
// import LayoutImageAdmin from './pages/layout_images/LayoutImage4';
// import LayoutImageAdmin from './pages/layout_images/LayoutImage2';


import Layoutvideo from './pages/Layoutvideo';
import ProjectsAgent from './pages/ProjectsAgent';
// import ProjectAdmin from './pages/ProjectsAdmin';
import LayoutImageAgent from './pages/layout/agents/LayoutImageAgent';
import AdminProjectList from './pages/layout/admin/AdminProjectList'
import AdminEditCreate from './pages/layout/admin/AdminEditCreate';
import AdminList from './pages/layout/admin/AdminList';
import AdminAgentList from './pages/layout/admin/AdminAgentList'
import AdminAgentEditCreate from './pages/layout/admin/AdminAgentCreate';

import LoginPage from './pages/Login/LoginPage';
import AgentEditCreate from './pages/layout/agents/AgentEditCreate';
import AgentList from './pages/layout/agents/AgentList';
import AgentsOffers from './pages/layout/agents/AgentsOffers';
import CustomerOffers from './pages/layout/agents/CustomerOffers';
import ImageTextReplacer from './pages/layout/agents/imgEdit';

import AgentProjectList from './pages/layout/agents/AgentProjectList';
import ForgetPassword from './pages/Login/ForgetPassword';
import ResetPassword from './pages/Login/ResetPassword';
import { default as ProfileAdmin } from './pages/layout/admin/MyProfile';
import { default as ProfileAgent } from './pages/layout/agents/MyProfile';
import { default as ChangePasswordAgent } from './pages/layout/agents/ChangePassword';
import { default as ChangePasswordAdmin } from './pages/layout/admin/ChangePassword';
import Wallet from './pages/layout/agents/Wallet';
import ProjectList from './pages/layout/withoutAuth/ProjectList';
import ProjectEdit from './pages/layout/withoutAuth/ProjectEdit';
import OtpVerify from './pages/Login/OtpVerify';
import Offers from './pages/layout/admin/offers';
import OfferEditCreate from './pages/layout/admin/OfferEditCreate';
import CustomerofferImages from './pages/layout/agents/CustomerofferImage';
import AgentofferImages from './pages/layout/agents/AgentofferImages';
import Commission from './pages/layout/admin/Commission';
import CommissionEditCreate  from './pages/layout/admin/CommissionEditCreate';
import CommissionView from './pages/layout/admin/CommissionView';
import Bankdetails from './pages/layout/admin/Bankdetails';
import Posters from './pages/layout/admin/Posters';
import Imagepage from './pages/layout/admin/Imagepage';


// ----------------------------------------------------------------------

function LogoOnlyLayout() {
  return (
    <>
      <Outlet />
    </>
  );
}


export default function Router() {
  const [account, setAccount] = useState({});

  useMemo(()=> {
    setAccount(JSON.parse(sessionStorage.getItem('user_details')))
  }, [sessionStorage.getItem('user_details')])

    const children = [
      { path: 'about', element: <About /> },
      { path: 'home', element: <Home /> },
      { path: 'blog', element: <Blog /> },
      { path: 'Contact', element: <Contact /> },
      { path: 'services', element: <Services /> },
      { path: 'project', element: <ProjectLogin /> },
      { path: 'projectAgent', element: <ProjectsAgent /> },
      { path: 'forgetPassword', element: <ForgetPassword /> },
      { path: 'resetpassword', element: <ResetPassword /> },
      { path: 'projectLayout', element: <ProjectList /> },
      { path: 'projectLayout/:id', element: <ProjectEdit /> },
      { path: 'sample_video', element: <Layoutvideo /> },
      { path: 'login', element: <LoginPage /> },
      { path: 'otp-verify', element: <OtpVerify /> },
      { path: '/', element: <Navigate to="/home" replace /> },
      { path: '*', element: <Navigate to="/home" replace /> },
      ]

      const AgentChildren = [
        { path: 'perfectlayout', element: <Dashboard />},
        { path: 'projectagent', element: <AgentProjectList /> },
        { path: 'agentUser/create', element: <AgentEditCreate /> },
        // { path: 'agentUser/:id', element: <AgentEditCreate /> },
        { path: 'agentUser', element: <AgentList /> },
        { path: 'projectAgent/:id', element: <LayoutImageAgent /> },
        { path: 'profile', element: <ProfileAgent /> },
        { path: 'changePassword', element: <ChangePasswordAgent /> },
        { path: 'wallet', element: <Wallet /> },
        { path: 'Customeroffers', element: <CustomerOffers /> },
        { path: 'agentoffers', element: <AgentsOffers /> },
        { path: 'customerofferimages/:id', element: <CustomerofferImages /> },
        { path: 'agentofferimages/:id', element: <AgentofferImages /> },
        { path: 'commission', element: <Commission /> },
        { path: 'commissionview/:id', element: <CommissionView /> },
        { path: 'bankdetails', element: <Bankdetails /> },
        { path: 'imgEdit', element: < Posters /> },
        { path: 'imagepage/:id', element: < ImageTextReplacer /> },
      ]

      const AdminChildern = [
        { path: 'perfectlayout', element: <Dashboard />},
        { path: 'projectadmin', element: <AdminProjectList /> },
        { path: 'projectAdmin/:id', element: <LayoutImageAdmin /> },
        { path: 'adminUser/create', element: <AdminEditCreate /> },
        { path: 'adminUser/:id', element: <AdminEditCreate /> },
        { path: 'adminUser', element: <AdminList /> },
        { path: 'profile', element: <ProfileAdmin /> },
        { path: 'changePassword', element: <ChangePasswordAdmin /> },
        { path: 'adminAgentUser', element: <AdminAgentList /> },
        { path: 'adminAgentUser/create', element: <AdminAgentEditCreate /> },
        { path: 'adminAgentUser/:id', element: <AdminAgentEditCreate /> },
        { path: 'offers/create', element: <OfferEditCreate /> },
        { path: 'offers/:id', element: <OfferEditCreate /> },
        { path: 'offers', element: <Offers /> },
        { path: 'Customeroffers', element: <CustomerOffers /> },
        { path: 'agentoffers', element: <AgentsOffers /> },
        { path: 'customerofferimages/:id', element: <CustomerofferImages /> },
        { path: 'agentofferimages/:id', element: <AgentofferImages /> },
        { path: 'commission', element: <Commission /> },
        { path: 'commission/:id', element: <CommissionEditCreate /> },
        { path: 'commissionview/:id', element: <CommissionView /> },
        { path: 'bankdetails', element: <Bankdetails /> },
        { path: 'imgEdit', element: < Posters /> },
        { path: 'imagepage/:id', element: < ImageTextReplacer /> },

      ]

      
  return useRoutes([
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: children,
    },
    {
      path: '/dashboard',
      element:  <RequireAuth>
      <DashboardLayout />
    </RequireAuth>,
      children: account?.user_type === 'agent' ? AgentChildren : AdminChildern,
    },
  ]);
}

function RequireAuth({ children }) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth) {
    toast.error('Please Login to Continue');
    return ( 
    <Navigate to="/login" state={{ location }} replace />
    );
  }

  return children;
}

function useAuth() {
  return sessionStorage.getItem('token');
}